import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/Login.vue')
  },
  {
    path:'/register',
    name:'Register',
    component: () => import('../views/account/Register.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/account/Forget.vue')
  },
  {
    path:'/admin',
    name:'Admin',
    component: () => import('../views/admin/Admin.vue')
  },
  {
    path:'/student',
    name:'Student',
    component: () => import('../views/student/Student.vue')
  },
  {
    path:'/teacher',
    name:'Teacher',
    component: () => import('../views/teacher/Teacher.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
