<template>
  <router-view/>
</template>

<style>
body,html,#app{
  margin: 0;
  padding: 0;
  height: 100%;
}
.el-loading-mask{
  border-radius: 10px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
h1,h2,h3,h4,h5,h6{
  color: var(--el-text-color-regular);
}
</style>
